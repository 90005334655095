<template>
  <div class="payment-status flex justify-center items-center">
    <PaymentStatus
      :paymentInformations="paymentInformations"
      :projectSummary="projectSummary"
      :langObj="langObj"
      :videoItems="videoItems"
      :surveyTypeID="surveyTypeID"
      v-if="paymentInformations !== null"
    ></PaymentStatus>
    <div
      v-else
      class="payment-status__loading flex justify-center items-center"
    >
      <i class="circle-loader"></i>Loading payment status...
    </div>
    <div class="payment-status__cta flex justify-center">
      <a-button @click="$router.push('/')"> Return to home </a-button>
    </div>
    <div class="payment-status__footer flex">
      <p>© 2022 Hotspex Labs</p>
      <ul class="flex">
        <li><a href="">Terms and conditions</a></li>
        <li><a href="">Contacts</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import PaymentStatus from "@/components/payment/PaymentStatus.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "PaymentConfirmation",
  components: {
    PaymentStatus,
  },
  data() {
    return {
      surveyId: null,
      paymentInformations: null,
      projectSummary: null,
      langObj: {},
      videoItems: null,
      surveyTypeID: null,
    };
  },

  methods: {
    getSurveyPaymetStatus() {
      const getSurveyPaymetStatus = new Promise((resolve) => {
        wsUtils.GetSurveyPaymetStatus(this.surveyId, resolve);
      });

      getSurveyPaymetStatus.then((data) => {
        this.paymentInformations = data.d;
      });
    },
  },
  mounted() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.surveyId = this.$route.query.SurveyId;
    this.getSurveyPaymetStatus();
    this.projectSummary = jsUtils.getSurveySummaryData();
    this.videoItems = this.projectSummary.creatives.map((item, index) => {
      return {
        ...item,
        sampleSize: this.projectSummary.sampleSize,
        key: index,
      };
    });
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
};
</script>

<style lang="scss" scoped>
.payment-status {
  flex-direction: column;
  padding: 50px 30px;
  &__cta {
    margin: 15px 0 0;
  }
  &__footer {
    width: 100%;
    max-width: 1190px;
    justify-content: space-between;
    padding-top: 40px;
    * {
      color: var(--lightText);
    }
    ul {
      li {
        margin: 0 11px;
        position: relative;
        &::after {
          position: absolute;
          right: -11px;
          top: 50%;
          transform: translateY(-50%);
          height: 16px;
          border-right: 1px solid var(--lightText);
          content: "";
        }
        &:last-child {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
        a {
          &:hover {
            color: var(--hsgrey);
          }
        }
      }
    }
  }
  &__loading {
    height: 544px;
  }
}
</style>
