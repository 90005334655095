<template>
  <div class="flex items-center justify-center sso-logout">
    <div class="sso-logout__info">
      <p>
        Thank you for using Hotspex Labs. <br />
        <span @click="loginUrl">Login</span>
      </p>
    </div>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "SamlssosSingout",
  data() {
    return {
      user: null,
    };
  },
  computed: {},
  methods: {
    loginUrl() {
      this.user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtils.readCookie("HS-STUDIO"));
      if (
        this.user.dashboardURL !== "" &&
        this.user.dashboardURL !== undefined
      ) {
        window.open(this.user.dashboardURL, "_self");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sso-logout {
  background: #e4edf2;
  flex-direction: column;
  padding: 50px 30px;
  &__info {
    max-width: 600px;
    margin: 0 auto;
    p {
      font-size: 26px;
      font-weight: 300;
      color: #000;
      span {
        color: var(--hsblue);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
