<template>
  <div class="payment-status__wrapper flex">
    <div
      class="payment-status__info"
      :class="{
        'payment-status__info--failed': paymentInformations.Status === 'Failed',
      }"
    >
      <img :src="setImageUrl" alt="" />
      <h1>
        Payment
        <span v-if="paymentInformations.Status === 'Success'">complete</span
        ><span v-else>failed</span>
      </h1>
      <p v-if="paymentInformations.Status === 'Success'">
        Thank you, your paymant has been successful.
      </p>
      <p v-else>
        Your paymant has been unsuccessful. Please try again in order to
        proceed.
      </p>
    </div>
    <div
      class="payment-status__details"
      :class="{
        'payment-status__details--failed':
          paymentInformations.Status === 'Failed',
      }"
    >
      <h2>Transaction details</h2>
      <a-descriptions bordered :column="2">
        <a-descriptions-item :label="langObj['s6-summary'].summaryProjectName">
          {{ projectSummary !== null ? projectSummary.projectName : "/" }}
        </a-descriptions-item>
        <a-descriptions-item :label="langObj['s6-summary'].summaryCategory">
          {{
            projectSummary !== null ? projectSummary.category.CategoryName : "/"
          }}
        </a-descriptions-item>
        <a-descriptions-item :label="langObj['s6-summary'].summaryAudience">
          <span
            v-html="projectSummary !== null ? projectSummary.audience : '/'"
          ></span>
        </a-descriptions-item>
        <a-descriptions-item
          :label="langObj['s6-summary'].summaryTotalSampleSize"
        >
          {{
            projectSummary !== null
              ? projectSummary.sampleSize * projectSummary.creativesCount
              : "/"
          }}
        </a-descriptions-item>
        <a-descriptions-item
          :label="langObj['s6-summary'].summaryCustomQuestions"
          :span="2"
        >
          <template v-if="projectSummary !== null">
            <ol class="mb-0 pl-15">
              <li
                v-for="(item, index) in projectSummary.customQuestions"
                :key="index"
              >
                {{ item }}
              </li>
            </ol>
          </template>
        </a-descriptions-item>
      </a-descriptions>

      <a-table
        :data-source="videoItems"
        :pagination="{ hideOnSinglePage: true }"
        class="launch-step__video-info"
        v-if="surveyTypeID !== 92"
      >
        <a-table-column
          width="45%"
          key="name"
          :title="langObj['s6-summary'].summaryVideoName"
          data-index="name"
        />
        <a-table-column
          key="thumbnail"
          :title="langObj['s6-summary'].summaryThumbnail"
          data-index="thumbnail"
          width="18.33%"
        >
          <template slot-scope="text, record">
            <img :src="record.coverImage" alt="" width="120" />
          </template>
        </a-table-column>
        <a-table-column
          key="duration"
          :title="langObj['s6-summary'].summaryDuration"
          data-index="duration"
          width="18.33%"
        />
        <a-table-column
          key="sampleSize"
          :title="langObj['s6-summary'].summarySampleSize"
          data-index="sampleSize"
          width="18.33%"
        />
      </a-table>

      <div class="payment-status__total">
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="Total price">
            <span class="flex items-center">
              ${{ paymentInformations.AmountPaid }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="flex justify-between items-center">
        <ul class="payment-status__details-list">
          <li>
            <strong>Transaction date: </strong>
            {{ paymentInformations.TransactionDate }}
          </li>
        </ul>
        <p class="payment-status__details-invoice-id">
          Invoice ID: {{ paymentInformations.InvoiceId }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentStatus",
  props: {
    paymentInformations: {
      type: Object,
    },
    projectSummary: {
      type: Object,
    },
    langObj: {
      type: Object,
    },
    videoItems: {
      type: Array,
    },
    transactionDetailsData: {
      type: Array,
      default() {
        return [
          {
            projectName: "Cloud Database",
            sampleSize: "75 / Creative",
            country: "Canada",
            language: "English",
            audience: "General Population",
          },
        ];
      },
    },
    surveyTypeID: {
      type: Number,
    },
  },
  data() {
    return {};
  },

  methods: {},
  computed: {
    setImageUrl() {
      let url = "/images/pay-success-1.png";
      if (this.paymentInformations.Status === "Failed") {
        url = "/images/pay-failure-1.png";
      }
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-status {
  &__wrapper {
    width: 100%;
    max-width: 1190px;
    background-color: #fff;
  }
  &__info {
    flex: 0 0 40%;
    padding: 30px 32px 25px;
    background-color: var(--hsblue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--failed {
      background-color: #e44546;
      img {
        margin-left: -77px;
      }
    }
    & * {
      color: #fff;
    }
    img {
      max-width: 360px;
    }
    h1 {
      text-transform: uppercase;
      font-weight: 600;
      margin: 14px 0 4px;
    }
    p {
      font-size: 20px;
      line-height: 33px;
      margin-bottom: 0;
      a {
        font-weight: 600;
      }
    }
  }
  &__details {
    flex: 1;
    padding: 40px 40px 25px;
    text-align: left;
    h2 {
      text-transform: uppercase;
      font-size: 30px;
      color: #000;
      margin-bottom: 23px;
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    .ant-descriptions {
      max-width: 250px;
      width: 100%;
    }
  }
  &__details-list {
    padding: 0;
    margin: 0;
    li {
      // &:first-child {
      //   font-size: 26px;
      //   margin-bottom: 2px;
      // }
      strong {
        color: #000;
      }
    }
  }
  &__details-invoice-id {
    text-align: right;
    font-size: 25px;
    color: #545454;
    margin: 0;
  }
}
</style>

<style lang="scss">
.payment-status {
  &__details {
    .ant-table-body {
      margin-bottom: 40px;
      .ant-table-thead {
        tr {
          th {
            background-color: var(--hsblue);
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            border-width: 0 1px 2px 0;
            border-style: solid;
            border-color: #fff;
            padding: 10px;
            border-radius: 0 !important;
          }
          td {
            background-color: #cfd5ea;
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            background-color: #cfd5ea;
            border-right: 1px solid #fff;
            text-align: center;
            color: #000;
            padding: 9px 10px;
          }
          &:hover {
            td {
              background-color: #cfd5ea;
            }
          }
        }
      }
    }
    .ant-descriptions-view {
      border: 0;
      border-radius: 0;
      margin-bottom: 40px;
    }
    .ant-descriptions-row {
      border-bottom-color: #fff;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background: var(--hsblue);
      color: #fff;
      padding: 10px;
    }
    .ant-descriptions-item-label {
      border-right-color: #fff;
      border-right-width: 2px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
    .ant-descriptions-item-content {
      background: #cfd5ea;
      color: #000;
    }
    &--failed {
      .ant-table-body {
        .ant-table-thead {
          tr {
            th {
              background-color: #e44546;
            }
          }
        }
      }
      .ant-descriptions-item-label {
        background-color: #e44546;
      }
    }
  }
}
</style>
