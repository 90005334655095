var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getImageData !== '')?_c('div',{staticClass:"report-details"},[_c('img',{ref:"reportImage",attrs:{"src":_vm.getImageData.imageURL,"alt":_vm.getImageData.imageName}}),(_vm.dotsLoader)?_c('p',{staticClass:"report-details__dots-loader flex items-center"},[_c('i',{staticClass:"circle-loader"}),_vm._v("Loading data... ")]):_vm._e(),(_vm.imageWidth !== 0)?_c('div',{staticClass:"report-details__dots"},_vm._l((_vm.getFilteredComments),function(item,index){return _c('div',{key:index,staticClass:"report-details__dots-item",class:{
          'is-active': item.isActive,
        },style:({
          left: (item.xPos / _vm.imageWidth) * 100 + '%',
          top: (item.yPos / _vm.imageHeight) * 100 + '%',
        })},[_c('span',{staticClass:"report-details__dots-item-circle",class:{
            'green-bcg': item.iconID === 1,
            'red-bcg': item.iconID === 2,
          },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.activeComment(index, !item.isActive)}}}),_c('div',{staticClass:"report-details__dots-tooltip"},[_c('span',{staticClass:"flex items-center"},[_c('strong',[_vm._v("spexID:")]),_vm._v(" "+_vm._s(item.spexID)+" "),_c('svg',{staticStyle:{"enable-background":"new 0 0 512 512"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","xml:space":"preserve"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.activeComment(index, !item.isActive)}}},[_c('path',{attrs:{"d":"m375.808 143.28-11.504-11.12-108.128 111.984L148.032 132.16l-11.488 11.12 108.512 112.384-108.512 112.368 11.488 11.12 108.144-111.984 108.128 111.984 11.504-11.12-108.512-112.368z"}}),_c('path',{attrs:{"d":"M0 0v512h512V0H0zm496.032 496.032H15.968V15.968h480.064v480.064z"}})])]),(item.comment !== '')?_c('p',[_vm._v(_vm._s(item.comment))]):_c('p',[_vm._v("[ ]")])])])}),0):_vm._e()]):_c('p',{staticClass:"loader"},[_c('i',{staticClass:"circle-loader"}),_vm._v("Loading data...")])])}
var staticRenderFns = []

export { render, staticRenderFns }