<template>
  <div class="error-page flex justify-center items-center">
    <div class="error-page__info flex items-center">
      <h1 class="mob-show">Oh no!</h1>
      <div class="error-page__info-image">
        <img src="/images/error.png" alt="Error" />
      </div>
      <div>
        <h1 class="mob-hide">Oh no!</h1>
        <p>
          That wasn't supposed to happen. <br /><br />
          We've encountered an error and our team is looking into it.
        </p>
      </div>
    </div>
    <div class="error-page__info flex items-center">
      <a-button type="primary" @click="backToHome" class="ant-btn-lg">
        Back to home page
      </a-button>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";

export default {
  name: "GlobalErrorPage",
  props: {
    surveyId: String,
  },
  data() {
    return {};
  },
  methods: {
    backToHome() {
      this.$router.push("/");
    },
  },
  activated() {
    let data;

    if (this.$route.params.id === "WS1UL_01") {
      data = {
        ErrorCode: this.$route.params.id,
        Description: `Data missing for User Id : ${this.$route.params.userId}`,
      };
    } else {
      data = {
        ErrorCode: this.$route.params.id,
        SurveyId: this.$route.params.surveyId,
        Description: this.$route.params.description,
      };
    }
    wsUtils.setErrorLog(data);
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  flex-direction: column;
  padding: 50px 30px;
  &__info {
    max-width: 890px;
    margin: 0 auto 25px;
    text-align: left;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      text-align: center;
      br {
        display: none;
      }
    }
    h1 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 60px;
      line-height: normal;
      margin-bottom: 8px;
      color: #ff8500;
      text-shadow: 2px 0px 1px #000;
      @media screen and (max-width: 800px) {
        text-shadow: none;
        margin-bottom: 15px;
      }
      &.mob-hide {
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      &.mob-show {
        display: none;
        @media screen and (max-width: 800px) {
          display: block;
        }
      }
    }
    p {
      font-size: 36px;
      font-weight: 600;
      color: #a45bcc;
      text-transform: uppercase;
      text-shadow: 2px 0px 1px #000;
      line-height: 46px;
      margin: 0;
      @media screen and (max-width: 800px) {
        text-shadow: none;
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  &__info-image {
    max-width: 400px;
    margin-right: 40px;
    @media screen and (max-width: 800px) {
      margin: 0 0 22px 0;
    }
    img {
      width: 100%;
      margin-bottom: 4px;
    }
  }
}
</style>
