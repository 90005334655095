<template>
  <div class="map-explorer">
    <div class="map-explorer__filters">
      <h1>
        Emotional Impact Explorer<span v-if="pageTitle !== ''">:</span>
        {{ pageTitle }}
      </h1>
      <div class="map-explorer__filters-item">
        <label for="">Filter</label>
        <a-select
          @change="selectFilter"
          show-search
          placeholder="Please select..."
          :key="Math.random()"
          :default-value="activeFilter ? activeFilter : undefined"
        >
          <a-select-option
            v-for="(item, index) in mapExplorerFilterList"
            :key="index"
            :value="item.filterName"
          >
            {{ item.filterName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="map-explorer__filters-item">
        <label>Show labels</label>
        <a-checkbox v-model="showLabels"> </a-checkbox>
      </div>
      <div class="map-explorer__filters-item">
        <label>Show key attributes</label>
        <a-checkbox v-model="showStrategicPins"> </a-checkbox>
      </div>
      <a-button type="primary" @click="getReport"> Export </a-button>
    </div>
    <div class="map-explorer__inner">
      <p class="flex" v-if="mapExplorerImage === ''">
        <i class="circle-loader"></i> Loading...
      </p>
      <div v-else class="map-explorer__image">
        <img
          :src="mapExplorerImage"
          class="map-explorer__image-heat"
          alt="Map explorer"
        />
        <div
          class="map-explorer__circle"
          :class="{
            'is-active': item.showPin || (showStrategicPins && item.strategic),
            'is-strong': item.strategic,
          }"
          v-for="(item, index) in mapExplorerValues"
          :key="index"
          :style="{
            left: item.xPos / 10 + '%',
            top: item.yPos / 6.69 + '%',
          }"
          title="Click to PIN"
        >
          <span
            class="map-explorer__trigger"
            @click="
              mapExplorerValues[index].showPin =
                !mapExplorerValues[index].showPin
            "
          ></span>
          <span class="map-explorer__labels" v-if="showLabels">{{
            item.attribute
          }}</span>
          <div class="map-explorer__circle-tooltip">
            {{ item.attribute }} [{{
              ((item.Score / item.BaseSize) * 100).toFixed(2)
            }}%]
            <svg
              @click="
                mapExplorerValues[index].showPin =
                  !mapExplorerValues[index].showPin
              "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
            >
              <path
                d="m375.808 143.28-11.504-11.12-108.128 111.984L148.032 132.16l-11.488 11.12 108.512 112.384-108.512 112.368 11.488 11.12 108.144-111.984 108.128 111.984 11.504-11.12-108.512-112.368z"
              />
              <path
                d="M0 0v512h512V0H0zm496.032 496.032H15.968V15.968h480.064v480.064z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtilsMapExplorer from "@/utils/wsUtilsMapExplorer.js";
export default {
  name: "MapExplorer",
  props: {},
  data() {
    return {
      mapExplorerImage: "",
      mapExplorerFilterList: null,
      mapExplorerValues: [],
      activeFilter: null,
      activeSurveyCellID: null,
      activePin: null,
      showLabels: true,
      showStrategicPins: false,
      circleValues: [],
      pageTitle: "",
      apiUrl: null,
    };
  },
  methods: {
    getMapExplorerImage(surveyId, filter) {
      let data = { SurveyCellID: surveyId, Filter: filter };
      const getMapExplorerImage = new Promise((resolve) => {
        wsUtilsMapExplorer.getMapExplorerImage(data, resolve);
      });

      getMapExplorerImage.then((data) => {
        this.mapExplorerImage = data;
      });
    },
    getMapExplorerFilterList(surveyId) {
      let data = { SurveyCellID: surveyId };
      const getMapExplorerFilterList = new Promise((resolve) => {
        wsUtilsMapExplorer.getMapExplorerFilterList(data, resolve);
      });

      getMapExplorerFilterList.then((data) => {
        this.mapExplorerFilterList = data;
      });
    },
    getMapExplorerValues(surveyId, filter) {
      let data = { SurveyCellID: surveyId, Filter: filter };
      const getMapExplorerValues = new Promise((resolve) => {
        wsUtilsMapExplorer.getMapExplorerValues(data, resolve);
      });

      getMapExplorerValues.then((data) => {
        let attrs = data.map((attr) => {
          return {
            xPos: Math.round(
              ((2 + attr.xPos) / 4) * 0.949 * 1000 + (0.051 * 1000) / 2
            ),
            yPos: Math.round(
              ((2 - attr.yPos) / 4) * 0.935 * 670 + (0.065 * 670) / 2
            ),
            BaseSize: attr.BaseSize,
            attribute: attr.attribute,
            Score: attr.Score,
            strategic: attr.strategic,
            showPin: false,
          };
        });
        this.mapExplorerValues = attrs;
      });
    },
    getReport() {
      if (
        window.location.hostname === "app.hotspexlabs.com" ||
        window.location.hostname === "pg.hotspexlabs.com" ||
        window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
        window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
        window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
      ) {
        this.apiUrl = `https://admin.hotspexlabs.com/Reports/getExplicitMapExplorerExcel?a=${this.$route.query.SurveyCellID}&b=${this.$route.query.Filter}`;
      } else {
        this.apiUrl = `https://staging-admin.hotspexstudio.com/Reports/getExplicitMapExplorerExcel?a=${this.$route.query.SurveyCellID}&b=${this.$route.query.Filter}`;
      }
      window.open(this.apiUrl, "_self", "noreferrer");
    },
    selectFilter(filterName) {
      if (this.$route.query.Filter !== filterName) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            Filter: filterName,
          }),
        });
        this.activeFilter = filterName;
        this.getMapExplorerImage(this.activeSurveyCellID, filterName);
        this.getMapExplorerValues(this.activeSurveyCellID, filterName);
      }
    },
    setActivePin(pinId) {
      if (this.activePin === pinId) {
        this.activePin = null;
      } else {
        this.activePin = pinId;
      }
    },
  },
  activated() {
    let query = this.$route.query;
    this.activeFilter = query.Filter;
    this.activeSurveyCellID = query.SurveyCellID;
    this.pageTitle = query.Title || "";

    this.getMapExplorerImage(this.activeSurveyCellID, this.activeFilter);
    this.getMapExplorerFilterList(this.activeSurveyCellID);
    this.getMapExplorerValues(this.activeSurveyCellID, this.activeFilter);
  },
};
</script>

<style lang="scss" scoped>
.map-explorer {
  &__filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 30px 15px 20px;
    border-bottom: 1px solid #e5e5e5;
    @media screen and (max-width: 800px) {
      padding: 15px 20px 10px;
      .ant-btn {
        margin-bottom: 10px;
      }
    }
    h1 {
      margin: 0;
      font-size: 18px;
      line-height: normal;
      flex: 1;
      text-align: left;
      @media screen and (max-width: 800px) {
        flex: 0 0 100%;
        margin-bottom: 15px;
      }
    }
  }
  &__filters-item {
    margin-left: 20px;
    &:last-of-type {
      margin-right: 30px;
      border-right: 1px solid #e5e5e5;
      padding-right: 20px;
    }
    @media screen and (max-width: 800px) {
      margin: 0 20px 10px 0;
      &:last-child {
        margin: 0 0 10px 0;
      }
    }
    label {
      margin-right: 8px;
      @media screen and (max-width: 800px) {
        font-size: 14px;
      }
    }
    .ant-select {
      width: 160px;
      @media screen and (max-width: 800px) {
        width: 140px;
      }
    }
  }
  &__labels {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__circle {
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    text-transform: capitalize;
    &.is-active {
      .map-explorer__circle-tooltip {
        visibility: visible;
        opacity: 1;
        transform: translateY(-108%);
        z-index: 1;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
    &.pointer-none {
      pointer-events: none;
    }
    &.is-strong {
      .map-explorer__labels,
      .map-explorer__circle-tooltip {
        font-weight: 600;
      }
    }
  }
  &__trigger {
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 1;
    &:hover {
      ~ .map-explorer__circle-tooltip {
        visibility: visible;
        opacity: 1;
        transform: translateY(-108%);
        z-index: 2;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
  &__circle-tooltip {
    height: 30px;
    position: relative;
    font-size: 14px;
    padding: 5px 24px;
    text-align: center;
    cursor: default;
    transform: translateY(-113%);
    border-radius: 4px;
    white-space: nowrap;
    background: #fefefe;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    &:hover {
      z-index: 2 !important;
    }
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: #fefefe transparent transparent transparent;
      left: calc(50% - 8px);
      top: 95%;
    }
    &.is-hide {
      display: none;
    }
    svg {
      width: 12px;
      height: 12px;
      margin-left: auto;
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 62px);
    padding: 20px;
    img {
      max-width: 100%;
    }
  }
  &__image {
    max-width: 1000px;
    position: relative;
    background: url("../../public/images/mapExplorer.svg") center no-repeat;
    background-size: cover;
  }
  &__image-heat {
    pointer-events: none;
  }
}
</style>
