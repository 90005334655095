<template>
  <div class="payment-status body-bcg">
    <div class="payment-status__wrapper flex">
      <div class="payment-status__info text-center">
        <img src="/images/pay-success-1.png" alt="" />
        <h1>STUDY IN PROGRESS</h1>
        <p>Success! Your study has been successfully queued for fielding.</p>
      </div>
      <div class="payment-status__details">
        <h2 class="section-title">Study details</h2>
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="Name">
            {{ studyName }}
          </a-descriptions-item>
          <a-descriptions-item label="Country">
            {{ studyCountry }}
          </a-descriptions-item>
          <a-descriptions-item label="Category">
            {{ studyCategory }}
          </a-descriptions-item>
          <a-descriptions-item label="Brand">
            {{ studyBrand }}
          </a-descriptions-item>
          <a-descriptions-item label="Legs(s)">
            <ul class="mb-0 pl-0">
              <li v-for="(item, index) in studyLegsName" :key="index">
                {{ item }}
                <br v-if="index < projectSummary.legsName.length" />
              </li>
            </ul>
          </a-descriptions-item>
          <a-descriptions-item label="Deep Dive Product(s)">
            <ul class="mb-0 pl-0">
              <li v-for="(item, index) in studyDeepDive" :key="index">
                {{ item
                }}<span
                  v-if="index + 1 < projectSummary.deepDiveProductsName.length"
                  >,</span
                >
                <br v-if="index < projectSummary.deepDiveProductsName.length" />
              </li>
            </ul>
          </a-descriptions-item>
          <a-descriptions-item label="Communication Objective(s)">
            {{ studyCommunicationObjective1
            }}<span
              v-if="
                projectSummary !== null &&
                projectSummary.chosenCommunicationObjective2 !== -1
              "
              >,</span
            >
            {{ studyCommunicationObjective2 }}
          </a-descriptions-item>
          <a-descriptions-item label="Sample per deep dive">
            {{ studySampleSize }}
          </a-descriptions-item>
          <a-descriptions-item label="Cost center">
            {{ studyCostCenter }}
          </a-descriptions-item>
          <a-descriptions-item label="Estimate completion date">
            {{ estimateCompletionDate }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Report option"
            v-if="user && user.SkinIdentifier !== 'pg'"
          >
            {{ reportOption }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Implicit component"
            v-if="user && user.SkinIdentifier !== 'pg'"
          >
            {{ implicitComponent ? "Enabled" : "Disabled" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Custom Questions"
            v-if="
              user &&
              user.SkinIdentifier !== 'pg' &&
              surveyType !== 'packtestextend'
            "
          >
            <ol class="mb-0 pl-15">
              <li v-for="(item, index) in customQuestions" :key="index">
                {{ item }}
              </li>
            </ol>
            <span v-if="customQuestions.length === 0">/</span>
          </a-descriptions-item>
        </a-descriptions>

        <div class="payment-status__total">
          <a-descriptions bordered :column="2">
            <a-descriptions-item label="Total price">
              <span class="flex items-center">
                ${{ formatPrice(studyPrice) }}
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="flex justify-between items-center">
          <p class="payment-status__details-invoice-id">
            Invoice ID:
            {{ studyId }}
          </p>
        </div>
      </div>
    </div>
    <div class="payment-status__info-details justify-center">
      <p>
        Your study titled "{{
          projectSummary !== null ? projectSummary.projectName : "/"
        }}" has been successfully queued for fielding and a confirmation email
        has been sent. Once QA is complete, fielding will begin and progress
        details can be viewed from within your
        <span @click="dashboardUrl" class="cursor-pointer"
          ><strong>Dashboard</strong></span
        >. <br />
        {{ priceInfoText }}
        <br /><br />
        <span class="cursor-pointer" @click="$refs.getPdf.submit()"
          ><u>Download a PDF copy of this confirmation.</u></span
        >
      </p>
    </div>
    <div class="payment-status__cta flex justify-center">
      <a-button @click="dashboardUrl"> Return to Dashboard </a-button>
    </div>
    <form :action="apiUrl" method="POST" ref="getPdf">
      <input
        :value="$route.params.id"
        id="surveyId"
        name="surveyId"
        type="hidden"
      />
    </form>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import priceFormating from "@/mixins/priceFormating.js";

export default {
  name: "LaunchInfo",
  mixins: [priceFormating],
  data() {
    return {
      projectSummary: null,
      editableSurvey: null,
      apiUrl: null,
      estimateCompletionDate: null,
      user: null,
      surveyType: null,
    };
  },
  computed: {
    priceInfoText() {
      let message = "";
      if (this.user && this.user.SkinIdentifier === "pg") {
        message = `Please note that cost center ${
          this.editableSurvey !== null
            ? this.editableSurvey.SurveyConfig.CostCenter !== ""
              ? this.editableSurvey.SurveyConfig.CostCenter
              : "/"
            : "/"
        } will be cross-billed for USD $${this.formatPrice(this.studyPrice)}.`;
      } else {
        message = `Please note that you will be billed for USD $${this.formatPrice(
          this.studyPrice
        )}.`;
      }
      return message;
    },
    studyName() {
      return this.projectSummary !== null
        ? this.projectSummary.projectName
        : "/";
    },
    studyCategory() {
      return this.projectSummary !== null && "category" in this.projectSummary
        ? this.projectSummary.category
        : "/";
    },
    studyCountry() {
      return this.projectSummary !== null ? this.projectSummary.country : "/";
    },
    studyBrand() {
      return this.projectSummary !== null ? this.projectSummary.brand : "/";
    },
    studyDeepDive() {
      return this.projectSummary !== null
        ? this.projectSummary.deepDiveProductsName
        : [];
    },
    studyLegsName() {
      return this.projectSummary !== null ? this.projectSummary.legsName : [];
    },
    studyCommunicationObjective1() {
      return this.projectSummary !== null
        ? this.projectSummary.chosenCommunicationObjective1
            .CommunicationObjectiveName
        : "/";
    },
    studyCommunicationObjective2() {
      return this.projectSummary !== null
        ? this.projectSummary.chosenCommunicationObjective2
            .CommunicationObjectiveName
        : "/";
    },
    studyAllCommunicationObjective() {
      let allCommunication = [];
      allCommunication.push(this.studyCommunicationObjective1);
      if (this.studyCommunicationObjective2 !== undefined) {
        allCommunication.push(this.studyCommunicationObjective2);
      }
      return allCommunication;
    },
    studySampleSize() {
      return this.editableSurvey && this.editableSurvey.audience
        ? this.editableSurvey.audience.sampleSize
        : "/";
    },
    studyCostCenter() {
      return this.editableSurvey !== null
        ? this.editableSurvey.SurveyConfig.CostCenter !== ""
          ? this.editableSurvey.SurveyConfig.CostCenter
          : "/"
        : "/";
    },
    studyId() {
      return this.editableSurvey !== null
        ? this.editableSurvey.SurveyConfig.SurveyID
        : "/";
    },
    studyPrice() {
      return this.projectSummary !== null
        ? this.projectSummary.surveyPackTestPrice
        : "/";
    },
    customQuestions() {
      return this.projectSummary !== null
        ? this.projectSummary.customQuestions
        : "/";
    },
    reportOption() {
      return this.projectSummary !== null
        ? this.projectSummary.ReportOption
        : "/";
    },
    implicitComponent() {
      return this.projectSummary !== null
        ? this.projectSummary.implicitComponent
        : "/";
    },
  },
  methods: {
    dashboardUrl() {
      if (this.user.SkinIdentifier === "pg") {
        window.open("https://vpt.pg.com", "_self", "noreferrer");
      } else {
        this.$router.push("/");
      }
    },
    getDateAfter10Days() {
      const today = new Date();
      const futureDate = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000);
      this.estimateCompletionDate = futureDate.toLocaleDateString();
    },
  },
  activated() {
    this.surveyType = jsUtils.readCookie("survey-type");
    this.user = jsUtils.getUserInfo();
    this.projectSummary = jsUtils.getSurveySummaryData();
    this.editableSurvey = jsUtils.getEditableSurvey();
    if (
      window.location.hostname === "app.hotspexlabs.com" ||
      window.location.hostname === "pg.hotspexlabs.com" ||
      window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
      window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
      window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
    ) {
      this.apiUrl = "https://admin.hotspexlabs.com/PackTestInvoice/Export";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/PackTestInvoice/Export";
    }
    this.getDateAfter10Days();
  },
};
</script>

<style lang="scss" scoped>
.payment-status {
  padding: 50px 30px;

  &__wrapper {
    width: 100%;
    max-width: 1190px;
    background-color: #fff;
    margin: 0 auto 40px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  }
  &__info {
    flex: 0 0 40%;
    padding: 30px 32px 25px;
    background-color: var(--hsblue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--failed {
      background-color: #e44546;
      img {
        margin-left: -77px;
      }
    }
    & * {
      color: #fff;
    }
    img {
      max-width: 360px;
    }
    h1 {
      text-transform: uppercase;
      font-weight: 600;
      margin: 14px 0 4px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
      a {
        font-weight: 600;
      }
    }
  }
  &__details {
    flex: 1;
    padding: 40px 40px 25px;
    text-align: left;
    h2 {
      font-size: 30px;
    }
    ::v-deep .ant-descriptions-item-label {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    .ant-descriptions {
      max-width: 263px;
      width: 100%;
    }
  }
  &__details-list {
    padding: 0;
    margin: 0;
    li {
      strong {
        color: #000;
      }
    }
  }
  &__details-invoice-id {
    text-align: right;
    font-size: 21px;
    color: #545454;
    margin: 0;
  }
  &__info-details {
    max-width: 920px;
    margin: 0 auto 25px;
    p {
      font-size: 18px;
    }
    strong {
      font-weight: 500;
    }
  }
}
</style>

<style lang="scss">
.payment-status {
  &__details {
    .ant-table-body {
      margin-bottom: 40px;
      .ant-table-thead {
        tr {
          th {
            background-color: var(--hsblue);
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            border-width: 0 1px 2px 0;
            border-style: solid;
            border-color: #fff;
            padding: 10px;
            border-radius: 0 !important;
          }
          td {
            background-color: #cfd5ea;
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            background-color: #cfd5ea;
            border-right: 1px solid #fff;
            text-align: center;
            color: #000;
            padding: 9px 10px;
          }
          &:hover {
            td {
              background-color: #cfd5ea;
            }
          }
        }
      }
    }
    .ant-descriptions-view {
      border-color: #b9b9b9;
      margin-bottom: 40px;
    }
    .ant-descriptions-row {
      border-bottom-color: #b9b9b9;
    }
    .ant-descriptions-item-label {
      padding: 9px 12px;
      font-size: 12px;
      font-weight: 600;
      border-color: #b9b9b9;
      background: #f0f0f0;
    }
    .ant-descriptions-item-content {
      width: 175px;
      padding: 9px 12px;
      font-size: 12px;
      border-color: #b9b9b9;
      background: #fff;
      color: #1c1c1c;
    }
    &--failed {
      .ant-table-body {
        .ant-table-thead {
          tr {
            th {
              background-color: #e44546;
            }
          }
        }
      }
      .ant-descriptions-item-label {
        background-color: #e44546;
      }
    }
  }
}
</style>
