var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-status body-bcg"},[_c('div',{staticClass:"payment-status__wrapper flex"},[_vm._m(0),_c('div',{staticClass:"payment-status__details"},[_c('h2',{staticClass:"section-title"},[_vm._v("Study details")]),_c('a-descriptions',{attrs:{"bordered":"","column":2}},[_c('a-descriptions-item',{attrs:{"label":"Name"}},[_vm._v(" "+_vm._s(_vm.studyName)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Country"}},[_vm._v(" "+_vm._s(_vm.studyCountry)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Category"}},[_vm._v(" "+_vm._s(_vm.studyCategory)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Brand"}},[_vm._v(" "+_vm._s(_vm.studyBrand)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Legs(s)"}},[_c('ul',{staticClass:"mb-0 pl-0"},_vm._l((_vm.studyLegsName),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < _vm.projectSummary.legsName.length)?_c('br'):_vm._e()])}),0)]),_c('a-descriptions-item',{attrs:{"label":"Deep Dive Product(s)"}},[_c('ul',{staticClass:"mb-0 pl-0"},_vm._l((_vm.studyDeepDive),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)),(index + 1 < _vm.projectSummary.deepDiveProductsName.length)?_c('span',[_vm._v(",")]):_vm._e(),(index < _vm.projectSummary.deepDiveProductsName.length)?_c('br'):_vm._e()])}),0)]),_c('a-descriptions-item',{attrs:{"label":"Communication Objective(s)"}},[_vm._v(" "+_vm._s(_vm.studyCommunicationObjective1)),(
              _vm.projectSummary !== null &&
              _vm.projectSummary.chosenCommunicationObjective2 !== -1
            )?_c('span',[_vm._v(",")]):_vm._e(),_vm._v(" "+_vm._s(_vm.studyCommunicationObjective2)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Sample per deep dive"}},[_vm._v(" "+_vm._s(_vm.studySampleSize)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Cost center"}},[_vm._v(" "+_vm._s(_vm.studyCostCenter)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Estimate completion date"}},[_vm._v(" "+_vm._s(_vm.estimateCompletionDate)+" ")]),(_vm.user && _vm.user.SkinIdentifier !== 'pg')?_c('a-descriptions-item',{attrs:{"label":"Report option"}},[_vm._v(" "+_vm._s(_vm.reportOption)+" ")]):_vm._e(),(_vm.user && _vm.user.SkinIdentifier !== 'pg')?_c('a-descriptions-item',{attrs:{"label":"Implicit component"}},[_vm._v(" "+_vm._s(_vm.implicitComponent ? "Enabled" : "Disabled")+" ")]):_vm._e(),(
            _vm.user &&
            _vm.user.SkinIdentifier !== 'pg' &&
            _vm.surveyType !== 'packtestextend'
          )?_c('a-descriptions-item',{attrs:{"label":"Custom Questions"}},[_c('ol',{staticClass:"mb-0 pl-15"},_vm._l((_vm.customQuestions),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0),(_vm.customQuestions.length === 0)?_c('span',[_vm._v("/")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"payment-status__total"},[_c('a-descriptions',{attrs:{"bordered":"","column":2}},[_c('a-descriptions-item',{attrs:{"label":"Total price"}},[_c('span',{staticClass:"flex items-center"},[_vm._v(" $"+_vm._s(_vm.formatPrice(_vm.studyPrice))+" ")])])],1)],1),_c('div',{staticClass:"flex justify-between items-center"},[_c('p',{staticClass:"payment-status__details-invoice-id"},[_vm._v(" Invoice ID: "+_vm._s(_vm.studyId)+" ")])])],1)]),_c('div',{staticClass:"payment-status__info-details justify-center"},[_c('p',[_vm._v(" Your study titled \""+_vm._s(_vm.projectSummary !== null ? _vm.projectSummary.projectName : "/")+"\" has been successfully queued for fielding and a confirmation email has been sent. Once QA is complete, fielding will begin and progress details can be viewed from within your "),_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.dashboardUrl}},[_c('strong',[_vm._v("Dashboard")])]),_vm._v(". "),_c('br'),_vm._v(" "+_vm._s(_vm.priceInfoText)+" "),_c('br'),_c('br'),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$refs.getPdf.submit()}}},[_c('u',[_vm._v("Download a PDF copy of this confirmation.")])])])]),_c('div',{staticClass:"payment-status__cta flex justify-center"},[_c('a-button',{on:{"click":_vm.dashboardUrl}},[_vm._v(" Return to Dashboard ")])],1),_c('form',{ref:"getPdf",attrs:{"action":_vm.apiUrl,"method":"POST"}},[_c('input',{attrs:{"id":"surveyId","name":"surveyId","type":"hidden"},domProps:{"value":_vm.$route.params.id}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-status__info text-center"},[_c('img',{attrs:{"src":"/images/pay-success-1.png","alt":""}}),_c('h1',[_vm._v("STUDY IN PROGRESS")]),_c('p',[_vm._v("Success! Your study has been successfully queued for fielding.")])])}]

export { render, staticRenderFns }